<template>
  <userSettingsPage />
</template>


<script>

import userSettingsPage from "@/components/userSettings/userSettingsPage.vue";

export default {
  name: "StreamsView",
  components: {
    userSettingsPage,
  },
};
</script>