<template>
  <div>
    <VRow class="align-center d-flex justify-content-center">
      <VCol cols="12" class="text-center">
        <h3 class="text-h4">{{ $t("profile.settings") }}</h3>
      </VCol>
    </VRow>
    <v-divider class="my-3 mb-5" />
    <VRow class="mt-5 pb-0">
      <VCol cols="3">
        <ProfileMenu />
      </VCol>
      <VCol cols="8">
        <ProfileForm
          :selectedMenu="selectedMenu"
          :user="user"
          :apiKey="apiKey"
          v-if="!loading"
        />
      </VCol>
    </VRow>
  </div>
</template>

<script>
import language from "@/mixins/language";
import EventBus from "@/events/EventBus";
import user from "@/mixins/user";
import ProfileAvatar from "./ProfileAvatar.vue";
import ProfileForm from "./ProfileForm.vue";
import ProfileMenu from "./ProfileMenu.vue";
import offersService from "@/services/request/offers/offersService";
import usersService from "@/services/request/users/usersService";

export default {
  name: "ProfileComponent",
  mixins: [language, user],
  components: {
    ProfileAvatar,
    ProfileForm,
    ProfileMenu,
  },
  data: () => ({
    user: {},
    // inputUser: {},
    // inputUserReset: {},
    photoPreview: false,
    changed: false,
    loading: true,
    userInfo: {},
    selectedMenu: "User",
    apiKey: ""
  }),

  async mounted() {
    EventBus.$on("on-change-selected-menu", (selectedMenu) => {
      this.selectedMenu = selectedMenu;
    });
    this.loading = true;
    await this.getUserInfo();
    await this.getApiKey();
    this.loading = false;
  },
  methods: {
    async getApiKey() {
      try {
        this.loading = true;
        const response = await usersService.getApiKey();
        this.apiKey = response.key;
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false;
      }
    },
    getUserInfo() {
      this.user = { ...this.getUser };
      // const userInfo = this.getUser;
      // if (userInfo) {
      // this.user = {
      // ...userInfo,
      // };

      // this.inputUser = {
      //   ...this.user,
      // };
      // this.inputUserReset = {
      //   ...this.reset,
      // };
      // }
    },
  },
};
</script>
