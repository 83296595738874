<template>
  <v-col cols="12" class="text-center">
    <v-row v-if="!user.image && !photoPreview">
      <v-col cols="12">
        <VIcon size="120">mdi-account-circle</VIcon>
      </v-col>
      <v-col cols="12">
        <label for="customer_photo">
          <VBtn class="btn-orange-bg" @click="getFile">{{
            $t("form.ChangePhoto")
          }}</VBtn>
        </label>
      </v-col>
    </v-row>
    <v-row v-else-if="user.image && !photoPreview">
      <v-col cols="12">
        <img :src="user.image" class="img-preview" />
      </v-col>
      <v-col cols="12">
        <label for="customer_photo">
          <VBtn class="btn-orange-bg" @click="getFile">{{
            "Change photo"
          }}</VBtn>
        </label>
      </v-col>
    </v-row>
    <VFileInput
      id="customer_photo"
      class="d-none"
      v-model="user.photo"
      accept="image/jpg, image/jpeg, application/pdf"
      @change="makePreview"
    />
    <v-row v-if="photoPreview" align="center" justify="center">
      <v-col cols="12" style="height: 144px">
        <img :src="photoPreview" class="img-preview" />
      </v-col>
      <v-col cols="12" v-if="!pre">
        <VBtn @click="onChangeImage" color="error">
          {{ $t("btn.delete") }}
        </VBtn>
      </v-col>
      <v-col cols="6" v-if="pre">
        <VBtn class="btn-orange-bg text-white" @click="getFile">{{
          "Change photo"
        }}</VBtn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { validationMixin } from "vuelidate";
import fileSizeValidation from "@/validators/fileSizeAvatar";
import EventBus from "@/events/EventBus";

export default {
  name: "ProfileAvatar",
  mixins: [validationMixin],
  data: () => ({
    photoPreview: false,
    pre: false,
  }),
  validations: {
    user: {
      photo: { fileSizeValidation },
    },
  },
  props: {
    user: {
      require: true,
      default: {},
    },
    changePhotoPreview: {
      require: false,
    },
    resetPhoto: {
      require: false,
    },
  },
  watch: {
    changePhotoPreview: {
      deep: true,
      handler() {
        this.pre = true;
      },
    },
    resetPhoto: {
      deep: true,
      handler() {
        this.onResetImage();
      },
    },
  },
  methods: {
    onResetImage() {
      this.photoPreview = false;
      this.pre = false;
    },
    onChangeImage() {
      delete this.user.photo;
      this.photoPreview = null;
      EventBus.$emit("on-change-image", this.user);
    },
    getFile() {
      const fileUpload = document.getElementById("customer_photo");
      if (fileUpload != null) {
        fileUpload.click();
      }
    },

    makePreview() {
      if (this.$v.user.photo.fileSizeValidation) {
        let photoPreview = null;
        const { photo } = this.user;
        const reader = new FileReader();
        reader.readAsDataURL(photo);
        reader.onloadend = (ev) => {
          photoPreview = ev.target.result;
          this.photoPreview = photoPreview;
          this.pre = false;
        };
      } else {
        delete this.user.photo;
        this.setErrorNotification(this.$t("form.errors.photoMaxSizeAvatar"));
      }
    },
  },
};
</script>

<style scoped lang="scss">
.img-preview {
  border-radius: 50%;
  min-width: 80px;
  width: 120px;
  height: 120px;
  object-fit: cover;
  .theme--dark & {
    border: thin solid rgba(255, 255, 255, 0.12);
  }
  .theme--light & {
    border: thin solid rgba(0, 0, 0, 0.12);
  }
}
</style>
