<template>
  <v-card tile>
    <v-list dense>
      <v-list-item-group
        v-model="selectedMenu"
        mandatory
        active-class="active-primary--text"
        color="primary"
      >
        <template v-for="(item, index) in filteredMenu">
          <v-list-item
            v-if="item.permission"
            :key="item.text"
            :value="item.text"
            active-class="active-primary--text"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="$t(`btn.${item.text}`)"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider
            inset
            v-if="index < menu.length - 1"
            :key="`${index}-divider`"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </v-card> 
</template>

<script>
import EventBus from "@/events/EventBus";
import user from "@/mixins/user";

export default {
  mixins: [ user],
  mounted() {
    this.selectedMenu = this.menu.find(
      (item) => item.icon === "mdi-account"
    );
  },
  data: () => ({
    selectedMenu: null,
    defaultMenu: "",
    menu: [
      { text: "settings", icon: "mdi-account", permission: true },
      { text: "password_change", icon: "mdi-key", permission: true },
    ],
  }),
  computed: {
    filteredMenu() {
      let menuItems = [...this.menu];

      if (this.permissions?.can_see_integrations) {
        menuItems.push({ text: "integrations", icon: "mdi-key-plus", permission: true });
      }

      return menuItems;
    },
  },
  watch: {
    selectedMenu: {
      handler() {
        EventBus.$emit("on-change-selected-menu", this.selectedMenu);
      },
    },
  },
};
</script>
