// eslint-disable-next-line import/no-cycle
import requestService from "../requestService";

const prefix = '/users';

export default {
  async getUsers(payload) {
    const response = await requestService.get(`${prefix}`, payload);
    return response?.data?.data;
  },
  async updateUser(userId, payload) {
    const response = await requestService.post(`${prefix}/${userId}`, payload);
    return response?.data?.data;
  },
  async deleteUser(userId, payload) {
    const response = await requestService.delete(`${prefix}/${userId}`, payload);
    return response?.data?.data;
  },
  // Roles
  async getRoles(payload) {
    const response = await requestService.get(`/roles`, payload);
    return response?.data?.data;
  },
  // Settings
  async updateUserSettings(payload) {
    const response = await requestService.post(`/profile/update`, payload, {}, false);
    return response?.data?.data;
  },
  async getApiKey(payload) {
    const response = await requestService.get(`/api-keys`, payload, {}, false);
    return response?.data?.data;
  },
  async updateApiKey(payload) {
    const response = await requestService.post(`/api-keys`, payload, {}, false);
    return response?.data?.data;
  },
  async userPasswordChange(payload) {
    return await requestService.post(`/password/change_password`, payload,{},false);
  },
};
