<template>
  <v-card class="mb-5">
    <v-tabs-items v-model="selectedMenu">
      <v-tab-item value="settings">
        <v-card-text>
          <v-row :class="`${$vuetify.breakpoint.xs ? 'justify-center' : ''}`">
            <v-col cols="4" :class="`${$vuetify.breakpoint.xs ? 'col-12' : ''} pa-5`">
              <v-row :class="`${$vuetify.breakpoint.xs ? 'mx-n2 mt-n2' : 'ml-1 mt-0'} `">
                <t-img
                  @success="changeImage"
                  @reset="resetImage"
                  @editChangeImage="editChangeImage"
                ></t-img>
              </v-row>
            </v-col>
            <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'col-12' : 'py-5 px-5'">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('form.name')"
                    dense
                    v-model="inputUser.name"
                    :maxLength="30"
                    color="primary"
                    outlined
                    @keypress="validationForNickName($event)"
                    @paste="validationForNickName($event)"
                    hide-details
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    dense
                    color="primary"
                    v-model="inputUser.email"
                    :label="$t('form.email')"
                    readonly
                    disabled
                    hide-details
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    dense
                    color="primary"
                    v-model="formattedPhoneNumber"
                    :label="$t('form.phone_number')"
                    readonly
                    disabled
                    hide-details
                    prefix="+38"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-tab-item>
      <v-tab-item value="password_change">
        <v-card-text>
          <v-row cols="12" class="px-0 py-5 mt-5">
            <v-col cols="6" :class="$vuetify.breakpoint.xs ? 'col-12' : ''">
              <v-text-field
                  outlined
                  class="iconPass br-10"
                  :label="$t('profile.current_pass')"
                  dense
                  color="primary"
                  v-model="passForm.currentPass"
                  :type="currentPasswordVisible ? 'text' : 'password'"
                  :append-icon="currentPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="currentPasswordVisible = !currentPasswordVisible"
                  :error-messages="passwordErrors"
                  v-on:keypress="validationForPassword($event)"
                  @paste="validationForPassword($event)"
              />
            </v-col>
            <v-col cols="6" :class="$vuetify.breakpoint.xs ? 'col-12' : ''">
              <v-text-field
                  outlined
                  class="iconPass br-10"
                  :label="$t('profile.new_pass')"
                  dense
                  color="primary"
                  v-model="passForm.newPass"
                  :type="newPasswordVisible ? 'text' : 'password'"
                  :append-icon="newPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="newPasswordVisible = !newPasswordVisible"
                  :error-messages="newPassErrors"
                  v-on:keypress="validationForPassword($event)"
                  @paste="validationForPassword($event)"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                  outlined
                  class="iconPass br-10"
                  :label="$t('profile.сonfirm_pass')"
                  dense
                  color="primary"
                  v-model="passForm.newPassRepeat"
                  :type="repeatPasswordVisible ? 'text' : 'password'"
                  :append-icon="repeatPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="repeatPasswordVisible = !repeatPasswordVisible"
                  :error-messages="newPassRepeatErrors"
                  v-on:keypress="validationForPassword($event)"
                  @paste="validationForPassword($event)"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-tab-item>
      <v-tab-item value="integrations">
        <v-card-text>
          <v-col cols="12" class="py-0 my-3 br-10">
            <v-text-field
                class="my-0 py-0 d-flex align-items-center br-10"
                dense
                outlined
                label="Api Key"
                v-model="inputApiKey"
                color="primary"
                @keypress="validationForApiKey"
                @paste="validationForApiKey"
            >
              <template v-slot:append>
                <v-btn icon class="mb-1 py-0" @click="copyToClipboard">
                  <v-icon class="icon-btn mb-0 py-0">mdi-content-copy</v-icon>
                </v-btn>
                <v-btn icon class="mb-1 py-0" @click="generateApiKey">
                  <v-icon class="icon-btn mb-0 py-0">mdi-refresh</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
    <v-card-actions :class="$vuetify.breakpoint.xs ? 'justify-center' : ''">
      <v-spacer v-if="!$vuetify.breakpoint.xs" />
      <v-col cols="auto">
        <v-btn :disabled="!changed && !changedApiKey && !passwordChanged" @click="onSave()">
          {{ $t("btn.save") }}
        </v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>


<script>
import language from "@/mixins/language";
import {validationForApiKey, validationForNickName, validationForPassword} from "@/mixins/helpers";
import ProfileAvatar from "./ProfileAvatar.vue";
import theme from "@/mixins/theme";
import { validationMixin } from "vuelidate";
import EventBus from "@/events/EventBus";
import _ from "lodash";
import usersService from "@/services/request/users/usersService";
import notifications from "@/mixins/notifications";
import tImgMixin from "@/mixins/tImgMixin";
import { mapActions } from "vuex";
import * as actionType from "@/store/modules/auth/types/actions";
import user from "@/mixins/user";
import {required, sameAs} from "vuelidate/lib/validators";
import passwordMask from "@/validators/passwordMask";

export default {
  name: 'ProfileForm',
  mixins: [validationMixin, language, theme, notifications, tImgMixin, user],
  components: {
    ProfileAvatar,
  },
  props: {
    selectedMenu: {
      require: true,
    },
    user: {
      required: true,
      type: Object,
    },
    apiKey: {
      required: true,
      type: String,
    },
  },
  validations: {
    passForm: {
      newPass: { required, passwordMask },
      newPassRepeat: { required, passwordMask, sameAsPassword: sameAs("newPass") },
      currentPass: { required, passwordMask }
    }
  },
  data: () => ({
    changed: false,
    copyUser: {},
    copyApiKey: "",
    inputApiKey: "",
    passwordChanged: false,
    currentPasswordVisible: false,
    newPasswordVisible: false,
    repeatPasswordVisible: false,
    passForm: {
      newPass: "",
      newPassRepeat: "",
      currentPass: ""
    },
    inputUser: {
      name: '',
      email: '',
      phone_number: '',
      image: null
    },
    changedApiKey: false,
    changedPassword: false
  }),
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.passForm.currentPass.$dirty) {
        return errors;
      }
      if (!this.$v.passForm.currentPass.passwordMask) {
        errors.push(this.$t("form.errors.PasswordMustBeLike"));

        return errors;
      }
      !this.$v.passForm.currentPass.required &&
      errors.push(this.$t("form.errors.CurrentPassRequired"));
      return errors;
    },
    newPassErrors() {
      const errors = [];
      if (!this.$v.passForm.newPass.$dirty) {
        return errors;
      }
      if (!this.$v.passForm.newPass.passwordMask) {
        errors.push(this.$t("form.errors.PasswordMustBeLike"));

        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.passForm.newPass.required && errors.push(this.$t("form.errors.NewPassRequired"));
      return errors;
    },
    newPassRepeatErrors() {
      const errors = [];
      if (!this.$v.passForm.newPassRepeat.$dirty) {
        return errors;
      }
      if (!this.$v.passForm.newPassRepeat.required) {
        errors.push(this.$t("form.errors.ConfirmPassRequired"));

        return errors;
      }
      if (!this.$v.passForm.newPassRepeat.passwordMask) {
        errors.push(this.$t("form.errors.PasswordMustBeLike"));

        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.passForm.newPassRepeat.sameAsPassword &&
      errors.push(this.$t("form.errors.PasswordsDontMatch"));
      return errors;
    },
    formattedPhoneNumber: {
      get() {
        return this.inputUser.phone_number ? this.inputUser.phone_number.replace(/^\+38/, '') : '';
      },
      set(value) {
        this.inputUser.phone_number = value ? `+38${value}` : '';
      }
    }
  },
  mounted() {
    this.initializeData();
  },
  methods: {
    validationForPassword,
    validationForApiKey,
    generateApiKey() {
      const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
      this.inputApiKey = Array.from({ length: 32 }, () =>
          chars.charAt(Math.floor(Math.random() * chars.length))
      ).join('');
    },

    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.inputApiKey);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.error("Clipboard error:", e);
        this.setErrorNotification(this.$t("profile.copy_error"));
      }
    },
    validationForNickName,
    ...mapActions("auth", {
      updateUserData: actionType.USER_UPDATE_DATA,
    }),
    initializeData() {
      this.inputUser = {
        name: this.user.name || '',
        email: this.user.email || '',
        phone_number: this.user.phone_number || '',
        image: this.user.image || null
      };
      
      if (this.inputUser.phone_number && !this.inputUser.phone_number.startsWith('+38')) {
        this.inputUser.phone_number = `+38${this.inputUser.phone_number}`;
      }

      this.inputApiKey = this.apiKey;
      this.copyApiKey = this.inputApiKey;

      this.copyUser = _.cloneDeep(this.inputUser);
      this.T_IMG_OBJECT = this.inputUser;
      this.T_IMG_COPY_OBJECT = this.copyUser;
      this.T_IMG_MEDIA_KEY = "image";
      
      if (this.inputUser.image) {
        EventBus.$emit("is-selected-image", this.inputUser.image);
      }
    },
    onSave() {
      console.log(this.selectedMenu)
      if(this.selectedMenu == 'settings') {
        this.onSaveSettings();
      } else if(this.selectedMenu == 'password_change') {
        this.onPasswordChange();
      } else if(this.selectedMenu == 'integrations') {
        this.onSaveApiKey();
      }
    },
    async onPasswordChange() {
        try {
          const formDataPass = new FormData();
          formDataPass.append("current_password", this.passForm.currentPass);
          formDataPass.append("password", this.passForm.newPass);
          formDataPass.append("password_confirmation", this.passForm.newPassRepeat);
          await usersService.userPasswordChange(formDataPass);

          this.passForm = {
            newPass: "",
            newPassRepeat: "",
            currentPass: ""
          };
          this.$v.$reset();
          this.setSuccessNotification(this.$t("profile.password_changed_successfully"));
        } catch (e) {
          this.setErrorNotification(e.error.description);
        }
    },
    async onSaveSettings() {
      try {
        const formData = new FormData();
        formData.append("_method", 'patch');
        const changedData = this.$getChangedData(this.inputUser, this.copyUser);
        changedData.forEach((data) => {
          formData.append(data.field, data.value);
        });
        const res = await usersService.updateUserSettings(formData);
        this.copyUser = _.cloneDeep(this.inputUser);
        await this.updateUserData(res);
        this.setSuccessNotification(this.$t("profile.successfully_updated"));
      } catch (e) {
        this.setErrorNotification(this.$t("profile.successfully_error"));
      }
    },
    async onSaveApiKey() {
      try {
        const formData = new FormData();
        formData.append("key", this.inputApiKey);
        await usersService.updateApiKey(formData);
        this.copyApiKey = this.inputApiKey;
        this.setSuccessNotification(this.$t("profile.successfully_updated"));
      } catch (e) {
        this.setErrorNotification(this.$t("profile.successfully_error"));
      }
    },
  },
  watch: {
    inputUser: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.copyUser, this.inputUser);
      },
    },
    inputApiKey: {
      deep: true,
      handler() {
        this.changedApiKey = this.copyApiKey !== this.inputApiKey;
      },
    },
    user: {
      handler() {
        this.initializeData();
      },
      deep: true
    },
    showPass: {
      deep: true,
      handler() {
        if (!this.showPass) {
          this.passwordChanged = false;
        }
      }
    },
    passForm: {
      deep: true,
      handler() {
        this.passwordChanged = !_.isEqual(this.passForm, {
          newPass: "",
          newPassRepeat: "",
          currentPass: ""
        });
      }
    },
  },
};
</script>